<template>
  <div id="container" class="container"></div>
  <van-popup v-model:show="show" closeable round position="bottom" :style="{ height: '35%' }">
    <router-link :to="'/xdetails/'+Popupdata.pkguid">
      <popups  :Popupdata='Popupdata'></popups>
    </router-link>
  </van-popup>
</template>
<script>
import popups from '@/components/Popup_map.vue'
export default{
  name:'baiduMap',
  props:['entelist','Watchdata'],
  watch: {
    Watchdata(a){
      this.mapMarkersadd(a);
      // this.baiduMaps(a);
      // this.baiduMaps2(a);
    }
  },
  data() {
    return {
      map:{},
      marker:[],
      show:false,
      Popupdata:{},
      geolocation:{},
    }
  },
  components:{
    popups
  },
  mounted() {
    //地图渲染
    this.baiduMaps();
  },
  methods: {
    //百度地图渲染
    baiduMaps(data){
      // this.map=new window.BMapGL.Map("container");
      // this.map.centerAndZoom("晋中市", 11); // 初始化地图,设置中心点坐标和地图级别
      // this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
      let that=this;
      if (typeof(data)=='undefined'||data=='') {
        console.log('map',data)
        var geolocation = new window.BMapGL.Geolocation();
        console.log('bd',geolocation)
        // geolocation.enableSDKLocation();
        geolocation.getCurrentPosition(function(r) {
          if (this.getStatus() == BMAP_STATUS_SUCCESS) {
            console.log('dw',r)
            that.map = new window.BMapGL.Map("container");    
            let point = new window.BMapGL.Point(r.point.lng, r.point.lat);    
            that.map.centerAndZoom(point, 13);    
            let marker = new window.BMapGL.Marker(point);        // 创建标注    
            that.map.addOverlay(marker);   
            that.mapMarkersadd();
            console.log(r.point.lat,r.point.lng,r.address.province+r.address.city);
          }
        });
        // return
      } else {
        that.map=new window.BMapGL.Map("container");
        that.map.centerAndZoom("晋中市", 11); // 初始化地图,设置中心点坐标和地图级别
        that.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
        that.mapMarkersadd();
      }
      
    },
    //地图添加标记点方法
    mapMarkersadd(){
      console.log('子组件传值数据',this.entelist);
      for (let li of this.entelist) {
        let jwdu = li.readdressgd.split(",");
        this.marker=[];
        this.marker = new BMapGL.Marker(
          new BMapGL.Point(Number(jwdu[0]), Number(jwdu[1]))
        );
        this.map.addOverlay(this.marker);
        this.mapMarkerInfo(li);
      }
    },
    //地图信息窗口
    mapMarkerInfo(data){
      let $this = this
      this.marker.addEventListener("click", function () {
        $this.Popupdata=data;
        $this.show = true;
      });
    },
    
  },
}
</script>

<style scoped lang="less">
.container {
  width: 100%;
  height: 105%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1;
}
.hu_tc{
  width: 80%;
  height: 6rem;
  // display: none;
  border-radius: 5px;
  background: white;
  position: fixed;
  left: 10%;
  bottom: 5rem;
  z-index: 99999;
}

</style>