<template>
   <div class="hu_map_box">
        <div class="headers">
            <!-- <div class="header-tree">
                <van-dropdown-menu>
                    <van-dropdown-item v-model="city" :options="option1" @change="Ontab"/>
                </van-dropdown-menu>
            </div> -->
            <div class="header-serarch">
                <van-search v-model="value" show-action :label="notice" background="white" placeholder="请输入搜索关键词" >
                    <template #action>
                        <div @click="onSearch">搜索</div>
                    </template>
                </van-search>
            </div>
        </div>
        <div id="container2" class="container2"></div>
        <div class="hu_Maps_box">
            <Maps ref="child" :entelist="entelist" :Watchdata='Watchdata'></Maps>
        </div>
   </div>
</template>
<script>
import {com_list,com_cam_list,com_cam_list_url,comcity_cam} from '@/api/index';
import Maps from '@/components/BaiduMap.vue'
export default {
    name:'Map',
    components:{
        Maps,
    },
    data() {
        return {
            value:'',
            Data:[],
            entelist:[],
            Watchdata:'',
            total:'',
            city:'',
            //城市选择
            option1: [],
            equery:{
                re_title:'',
                city:'',
                page:1,
                pagesize:1000,
            },
            map:{},
            notice:''
        }
    },
    mounted() {
        //执行方法
        this.enterpriseL();
        this.C_itys();
        this.baiduMapsFun2();
    },
    methods: {
        //方法
        enterpriseL(){
            this.equery.city=this.city;
            com_list(this.equery).then((res) => {
                this.Watchdata=res.data.mydata.m_all;
                this.entelist=res.data.mydata.data;
                this.total=res.data.mydata.pageall; 
                // this.baidulits=[];
                // for (let i = 0; i < this.entelist.length; i++) {
                //     this.baidulits=this.baidulits.concat(this.entelist[i].readdressgd);
                // }
            }).catch((err) => {
                console.log(err)
            });
        },
        //定位方法
        baiduMapsFun2(){
            this.map=new window.BMapGL.Map("container2");
            this.map.centerAndZoom("晋中市", 11); // 初始化地图,设置中心点坐标和地图级别
            this.map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
            var geolocation = new window.BMapGL.Geolocation();
            let that=this;
            geolocation.getCurrentPosition(function(r) {
                if (this.getStatus() == BMAP_STATUS_SUCCESS) {
                    that.notice=r.address.city;
                    console.log('一级数据',that.notice);
                    // console.log(r.point.lat,r.point.lng,r.address.province+r.address.city);
                }
            });
        },
        C_itys(){
            let query={
                type:'ary'
            }
            comcity_cam(query).then((res) => {
                let list=[
                    {
                        text: "全部",
                        value: "",
                    }
                ];
                for (let x of res.data.mydata.names) {
                    list.push({
                        text: x,
                        value: x,
                    })
                }

                this.option1=list;
            }).catch((err) => {
                console.log(err)
            });
        },
        Ontab(data){
            console.log('data',data)
            this.entelist=[];
            this.enterpriseL();
            this.$refs.child.baiduMaps(data);
        },
        onSearch(){
            console.log('ss',this.value)
            this.equery.re_title=this.value;
            this.equery.page=1;
            this.equery.pagesize=1000;
            this.entelist=[];
            this.enterpriseL();
            this.$refs.child.baiduMaps(this.value);
        }
    },
}
</script>
<style scoped lang="less">
.headers {
  width: 100%;
  display: flex;
  background: #ffffff;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
//   .header-tree {
//     width: 25%;
//   }
  .header-serarch {
    width: 100%;
  }
  .tzc_search{
    color: rgb(0, 0, 0);
  }
}
/deep/ .van-dropdown-menu__bar {
  position: relative;
  display: flex;
  box-shadow: 0px 0px 0px #ffffff !important;
  background-color: #ffffff;
}
/deep/ .van-dropdown-menu__title {
  color: #000000;
  font-size: 0.9rem;
  font-weight: bold;
}
/deep/ .van-dropdown-menu__title::after {
  position: absolute;
  top: 50%;
  right: -4px;
  margin-top: -5px;
  border: 3px solid;
  border-color: transparent transparent #c0bfbf #c0bfbf !important;
  transform: rotate(-45deg);
  opacity: 0.8;
  content: "";
}
/deep/ .van-dropdown-item__option--active {
    color: #000000;
}
/deep/ .van-dropdown-item__option--active .van-dropdown-item__icon {
    color: #000000;
}
/deep/ .van-icon-location-o{
    font-size: 20px;
}
.hu_Maps_box{
    width: 100%;
    height: 95%;
    position: fixed;
    left: 0;
    top:0;
    overflow: hidden;
    z-index: 99 !important;
}
</style>