<template>
    <div class="hu_list_unit">
        <h4>
            <div>{{Pdata.retitle}}</div>
            <p><span class="iconfont icon-shexiangtou3"></span></p>
        </h4>
        <div class="hu_score_box">
            <div class="next_hu_score">
                <van-rate
                v-model="Pdata.reisgood"
                :size="20"
                color="#ffd21e"
                void-icon="star"
                void-color="#eee"
                readonly
                />
                <div class="next_hu_list">0条</div>
            </div>
            <div class="hu_score_fabulous">
                <div>
                    <van-icon name="eye-o" />
                    <span>{{Pdata.rehits}}</span>
                </div>
                <!-- <div>
                    <van-icon name="good-job-o" />
                    <span>12</span>
                </div> -->
            </div>
        </div>
        <div class="hu_address_box">
            <div>{{Pdata.readdress}}</div>
            <div>{{Pdata.ecname}}</div>
        </div>
    </div>
</template>

<script>
export default {
  name:'Popup_map',
  props:['Popupdata'],
  watch: {
    Popupdata(a){
        console.log("弹窗数据监听",a)
        this.to_update(a);
    }
  },
  data() {
    return {
      Pdata:{},
      value2:3,
    }
  },
  components:{},
  mounted() {
    //地图渲染
    this.to_update();
  },
  methods: {
    //百度地图渲染
    to_update(){
        this.Pdata=this.Popupdata;
    },
  },
}
</script>
<style scoped lang="less">
.hu_list_unit{
    // width: 100%;
    height: auto;
    margin-top: 1.5rem;
    padding: 0 0.5rem;
    // border-bottom: 1px solid rgb(230, 230, 230);
    h4{
        width: 100%;
        div{
            width: auto;
            max-width: 70%;
            line-height: 2rem;
            font-size: 13px;
            color: rgb(73, 73, 73);
            float: left;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
        p{
            display: inline-block;
            float: left;
            margin-left: 0.5rem;
            width: 1rem;
            height: 1rem;
            margin-top: 0.5rem;
            background: rgb(27, 204, 228);
            border-radius: 5px;
            text-align: center;
            line-height: 1rem;
            .icon-shexiangtou3{
                color: rgb(255, 255, 255);
                font-size: 10px;
            }
        }
    }
    .hu_score_box{
        width: 100%;
        height: auto;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        font-size: 13px;
        color: rgb(73, 73, 73);
        .next_hu_score{
            width: auto;
            padding-top: 0.5rem;
            /deep/ .van-rate--readonly{
                float: left;
            }
            .next_hu_list{
                float: left;
                margin-left: 0.5rem;
            }
        }
        .hu_score_fabulous{
            width: auto;
            padding-top: 0.5rem;
            div{
                float: left;
                margin-left: 0.5rem;
            }
        }
    }
    .hu_address_box{
        width: 100%;
        height: auto;
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
        display: flex;
        display: -webkit-flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        div{
            color: rgb(133, 133, 133);
            font-size: 12px;
        }
        div:nth-of-type(1){
            width: 65%;
            overflow: hidden;
            text-overflow:ellipsis;
            white-space: nowrap;
        }
    }
}
</style>